import { Component, OnInit, Inject, EventEmitter, ElementRef, ViewChild, Output,Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdleTimeOutSettings, DialogTypes } from '../../shared/Constants';
import { BehaviorSubject, interval } from 'rxjs';
import { GlobalApiService } from '../../shared/services/global/global-api/global-api.service';
import { MatChipInputEvent, MatRadioChange, MatSnackBar } from '@angular/material';
import { DataRetreivalService } from '../services/dataRetreival/data-retreival.service';
import { GlobalSrvConstantsService } from '../services/global/global-constants/global-srv-constants.service';
import { SubReconcileForceMatchUploadComponent } from 'src/app/returns/gstr2-a/sub-components/sub-reconcile-force-match-upload/sub-reconcile-force-match-upload.component';
import { getCurrentDateTimeForFileName, getCurrentFinancialYear } from '../helpers/date-time-helper';
import { Gstr2aService } from '../services/Returns/gstr2-a/gstr2a.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { GSTR1Report } from 'src/app/returns/gstr-one/sub-components/gstr1-model';
import { ListMonthYear, ListOfYearMonthYL } from '../models/global/get-search-dropdown';
import { ReturnsService } from '../services/Returns/gstr-one.service';
import { GetSrvSharedService } from '../services/get/get-srv-global/get-srv-shared.service';
import { DatePipe } from '@angular/common';
import { ListOfGstin } from '../../shared/models/global/get-search-dropdown';
import { VendorGSTINViewModel } from '../../shared/models/recon/gstr2a-recon';
import { DataColumnList } from '../models/get/get-md-global/get-column-list';

@Component({
  selector: 'app-bo-confirmation-dialog',
  templateUrl: './bo-confirmation-dialog.component.html',
  styleUrls: ['./bo-confirmation-dialog.component.css'],
  providers: [DatePipe],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1})) 
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0})) 
      ])
    ])
  ]
})
export class BoConfirmationDialogComponent implements OnInit {
  countDown: number;
  reasonfordeletion:string;
  remarks:string;
  checkIsActivated:boolean = false;
  emailInterval: string = 'Once';
  emailIteration: string = '1';
  statusType: any = [];
  gstr1SaveProceedStatus : string;
  deleteData:string[]=[];
  onActivate = new EventEmitter();
  OnClickOfGSTR1ReportDownload = new EventEmitter();
  OnClickOfGSTR1SaveProceed = new EventEmitter();
  OnClickOfgstRefundProceed = new EventEmitter();
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  selectedFileName: any;
  fileDesc: string;
  fileName: string;
  file: any;
  isUploading = false;
  fileSizeLimit: number = 2000;
  fileSizeLimitMsg: string;
  fileSize: number;
  reportPeriodSelected : string;
  listOfReportedPeriod: string[] = [];
  PeriodValues: ListOfYearMonthYL[]=[];
  financialYear  = [];
  currenctFY:string;
  listOfReportedMonth  = [{display:"Mar",value:'03'},{display:"Feb",value:'02'},{display:"Jan",value:'01'},{display:"Dec",value:'12'},{display:"Nov",value:'11'},{display:"Oct",value:'10'},{display:"Sep",value:'09'},{display:"Aug",value:'08'},{display:"Jul",value:'07'},{display:"Jun",value:'06'},{display:"May",value:'05'},{display:"Apr",value:'04'}];
  isExpandGet: boolean[] = [];
  reconType:string = "GSTR2A";
  requestId = 0;
  panelOpenState :any;
  ITCEligible : number = 0;
  PeriodSelectedForAccept : string =null;
    claimEligiblePull: number = 0;
    selectedReconTypeForRefund: number = null;
    selectedReconTypeForRefund_Inwards: number = null;
  listOfCtin: ListOfGstin[] = [];
  VendorCTINs: string[] = [];
  VendorGSTINRequestInputs: VendorGSTINViewModel;
  EntityGSTINList: string = "";
  PeriodList: string = "";
  Autoremarks : string = "";
  currentOrgID :any;
  tabName:string = "";
  IsMultiGSTIN:boolean = false;
  OverwritedataModeSelected:string = "No";
  forceMatchGrid: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pageSize: number = 5;
  currentPage: number = 1;
  @Input() filterPagination: boolean = true;
  forceMatchGridPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  forceMatchColumnList:   BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Output() fileUploadedEvent = new EventEmitter<any>();
  isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(public dialogRef: MatDialogRef<BoConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogTemp: any, 
    private globalsrvc: GlobalApiService,
    private dialog: MatDialog,
    private dataRetreivalService: DataRetreivalService,
    private gstr2aService:Gstr2aService,
    private _snackBar: MatSnackBar,
    private globalApiService: GlobalApiService,
    private returnsService: ReturnsService,
    private getColumnList: GetSrvSharedService,
    private datePipe: DatePipe
    ){  }

  ngOnInit(): void {
    this.isTableMultiSelect.next(false);
    this.currentOrgID = GlobalApiService.getCurrentOrganizationID();
    if (this.dialogTemp.Type == DialogTypes.IdleTime) {
      this.countDown = IdleTimeOutSettings.TimeOutAfterIdleTime;
      interval(1000).subscribe(() => {
        this.countDown = this.countDown - 1;
        if (this.countDown == 0) {
          this.dialogRef.close(false);  
        }
      })
    }
    if(this.dialogTemp.Type == DialogTypes.cancelEwaybills)
    {
      this.deleteData = this.dialogTemp.deleteData;
    }
    if(this.dialogTemp.Type == DialogTypes.AutoRecon){
      this.currenctFY = getCurrentFinancialYear();
      this. getFinancialYear();
    }
    if(this.dialogTemp.Type == DialogTypes.AutoReconUpload){
      this.reconType = this.dialogTemp.AdditionalInfo.type;
      this.tabName =  this.dialogTemp.AdditionalInfo.tabName;
    }
    if(this.dialogTemp.Type == DialogTypes.AutoReconRequestEmail){
      this.requestId = this.dialogTemp.AdditionalInfo.requestId;
    }
    if(this.dialogTemp.Type == DialogTypes.AutoReconForceUpload || this.dialogTemp.Type == DialogTypes.AutoReconDeLinkUpload){
      this.reconType = this.dialogTemp.AdditionalInfo.reportName.substr(10,6);
      this.tabName =  this.dialogTemp.AdditionalInfo.tabName;
    }
    if(this.dialogTemp.Type == DialogTypes.ManualReconVendorGSTINSelection){
      this.PeriodList = this.dialogTemp.Message;
      this.EntityGSTINList = this.dialogTemp.AdditionalInfo;
    }
    if(this.dialogTemp.Type == DialogTypes.DownloadGSTR1PopUp){
      if(this.dialogTemp.AdditionalInfo == true){
        this.IsMultiGSTIN = true;
        this.isDisabled = !this.isDisabled;
      }
      else{
        this.IsMultiGSTIN = false;
      }
    }
    if(this.dialogTemp.Type==26 || this.dialogTemp.Type==30){
      this.getForceColumnList();
      // this.getForceMatchGridDetails();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick():void{
    this.dialogRef.close(true);
  }
  RemoveTransactionalData():void{
    this.dialogRef.close(this.reasonfordeletion)
  }
  UpdateRemarks():void{
    this.dialogRef.close(this.dialogTemp.AdditionalInfo)
  }
  onActivateClick():void{
   this.checkIsActivated = true;
   this.onActivate.emit(this.checkIsActivated);
  }
  isDisabled:boolean = true;
  DownloadGSTR1Report(reportType : string) :void {
    //this.gstr1ReportType = reportType;
    if(reportType == 'GSTR1VSIRP'){
      this.isShow = !this.isShow;
      this.isDisabled = !this.isDisabled;
    }
  }

  OnSubmitDownloadGSTR1Report(reportType : string){
    let gstr1ReportType = new GSTR1Report();
    gstr1ReportType.ReportType = reportType;
    gstr1ReportType.ToleranceByTaxableAmt = this.toleranceByTaxableAmt;
    gstr1ReportType.ToleranceByTaxAmt = this.toleranceByTaxAmt;
    this.OnClickOfGSTR1ReportDownload.emit(gstr1ReportType);
  }

  OnSubmitDownloadGSTR1PDFReport(reportType : string){
    let gstr1ReportType = new GSTR1Report();
    gstr1ReportType.ReportType = reportType;
    gstr1ReportType.ToleranceByTaxableAmt = this.toleranceByTaxableAmt;
    gstr1ReportType.ToleranceByTaxAmt = this.toleranceByTaxAmt;
    this.OnClickOfGSTR1ReportDownload.emit(gstr1ReportType);
  }

  toleranceByTaxableValue = new FormControl();
  toleranceByTaxAmount = new FormControl();
  toleranceByTaxableAmt:number = 1;
  toleranceByTaxAmt:number = 1;
  onTaxableValueChange($event){
    this.toleranceByTaxableAmt = +$event.target.value;
  }

  onTaxAmountChange($event){
      this.toleranceByTaxAmt = +$event.target.value;
  }
  ShowGSTR1EinvoiceReconInfo(processStatus : string) :void {
    this.gstr1SaveProceedStatus = processStatus;
    this.OnClickOfGSTR1SaveProceed.emit(this.gstr1SaveProceedStatus);

  }

  ShowGSTRefundApplicationPage(processStatus : string) :void {
    this.OnClickOfgstRefundProceed.emit(processStatus);

  }
  
  
  Upload2AReconReport(uploadType) {
    this.dialogRef.close(true);
    this.dialog.open(SubReconcileForceMatchUploadComponent, {
        width: '450px',
        data: { 
          requestID: this.dialogTemp.AdditionalInfo.requestID, 
          actionType: uploadType, 
          gstin: this.dialogTemp.AdditionalInfo.gstin,
          reportType: this.dialogTemp.AdditionalInfo.reportName.includes("AutoRecon") ? "AutoRecon" : this.dialogTemp.AdditionalInfo.reportName ,
          type: this.reconType,
          tabName: this.dialogTemp.AdditionalInfo.tabName,
          requestType:this.dialogTemp.AdditionalInfo.type
        },
    });
  }

  Download2AReconReport(actionType) {
    console.log(this.dialogTemp.AdditionalInfo);
    let requestID = this.dialogTemp.AdditionalInfo.requestID;
    let fileID = this.dialogTemp.AdditionalInfo.fileID;
    this.dialogRef.close(true);
    this.dataRetreivalService.downloadReconFile(fileID,requestID,actionType)
    .subscribe((data: any) => {
      console.log(data);
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'Recon_'+ actionType  +'_' + fileID;
      anchor.href = url;
      anchor.click();
    },error => {
      this._snackBar.open('Missing recon template / Action not yet performed.', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
    });
  });
  }

  DownloadAutoReconUploadStatus(actionType) {
    this.dialogRef.close(true);
    this.dataRetreivalService.downloadAutoReconFile(actionType, this.reconType)
    .subscribe((data: any) => {
      if(data.type == 'application/json'){
        this._snackBar.open('Successully forcematch done. No error to display.', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['success-snackbar']
        });
      }
      else{
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'AutoRecon_'+ actionType;
      anchor.href = url;
      anchor.click();
      }
    },error => {
      this._snackBar.open('Missing recon template / Action not yet performed.', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
    });
  }

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  ccEmails: string[] = [];
  toEmails: string[] = [];
  isVendorMail : number = 0;
  sendReconMail(){
    if(this.isVendorMail == 1){
      if(this.toEmails.length > 2){
        this._snackBar.open('Max. two email(s) allowed.', 'OK', {
          duration: 2000,
          panelClass: ['alert-snackbar']
        });
        return;
      }
    }
    if(this.ccEmails.length > 2){
      this._snackBar.open('Max. two email(s) allowed.', 'OK', {
        duration: 2000,
        panelClass: ['alert-snackbar']
      });
      return;
    }
    let reqID = this.dialogTemp.AdditionalInfo.requestID;
    let reportType = this.dialogTemp.ReportType;

      this.gstr2aService.sendReconMail(reqID,reportType,this.ccEmails.join(';'),this.emailInterval,this.emailIteration,this.statusType.join(','),this.isVendorMail,this.toEmails)
      .subscribe((data: any) => {
        console.log(data);
        if ('200'.localeCompare(data.status) === 0){
          this._snackBar.open('Mail submitted for processing succcessfully.', 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['success-snackbar']
          });
        }
        else {
          this._snackBar.open('Mail sent failed.Please retry once again.', 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['alert-snackbar']
          });
        }
      },error => {
        this._snackBar.open('Error in mail service.' + error.message, 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
        });
      });
  }

  isEmailIterationDisabled:boolean =true;
  onEmailIntervalChange(){
    if(this.emailInterval === 'Once'){
      this.emailIteration = '1';
      this.isEmailIterationDisabled = true;
    }
    else{
      this.isEmailIterationDisabled = false;
    }
  }
  
  sendAutoReconMail(){
    if(this.isVendorMail == 1){
      if(this.toEmails.length > 2){
        this._snackBar.open('Max. two email(s) allowed.', 'OK', {
          duration: 2000,
          panelClass: ['alert-snackbar']
        });
        return;
      }
    }
    if(this.ccEmails.length > 2){
      this._snackBar.open('Max. two email(s) allowed.', 'OK', {
        duration: 2000,
        panelClass: ['alert-snackbar']
      });
      return;
    }
    let reportType = this.dialogTemp.ReportType;
    let gstins = this.dialogTemp.AdditionalInfo.Gstins;
    let periods = this.dialogTemp.AdditionalInfo.FyPeriods;
    let action = this.dialogTemp.AdditionalInfo.Action;
    this.gstr2aService.sendAutoReconMail(reportType,gstins,periods,this.ccEmails.join(';'),action,this.requestId,this.emailInterval,this.emailIteration,this.statusType.join(','),this.isVendorMail,this.toEmails.join(';'))
    .subscribe((data: any) => {
      console.log(data);
      if ('200'.localeCompare(data.status) === 0){
        this._snackBar.open('Mail submitted for processing succcessfully.', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['success-snackbar']
        });
      }
      else {
        this._snackBar.open('Mail sent failed.Please retry once again.', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
        });
      }
    },error => {
      this._snackBar.open('Error in mail service.' + error.message, 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['danger-snackbar']
      });
    });
  }

  addChips(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      var _email = value.trim();
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if(_email !== '' && !pattern.test(_email)){
        this._snackBar.open('Invalid Email.', 'OK', {
          duration: 1000,
          panelClass: ['alert-snackbar']
        });
      }
      else{
        this.ccEmails.push(_email);
      }
    }
    if (input) {
      input.value = '';
    }
    if(this.ccEmails.length > 2){
      
      console.log(this.ccEmails);
      const index = this.ccEmails.length -1;
      if (index >= 0) {
        this.ccEmails.splice(index, 1);
      }  
      this._snackBar.open('Max. two email(s) allowed.', 'OK', {
        duration: 2000,
        panelClass: ['alert-snackbar']
      });  
    }
  }

  addToChips(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      var _email = value.trim();
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if(_email !== '' && !pattern.test(_email)){
        this._snackBar.open('Invalid Email.', 'OK', {
          duration: 1000,
          panelClass: ['alert-snackbar']
        });
      }
      else{
        this.toEmails.push(_email);
      }
    }
    if (input) {
      input.value = '';
    }
    if(this.toEmails.length > 2){
      
      console.log(this.toEmails);
      const index = this.toEmails.length -1;
      if (index >= 0) {
        this.toEmails.splice(index, 1);
      }  
      this._snackBar.open('Max. two email(s) allowed.', 'OK', {
        duration: 2000,
        panelClass: ['alert-snackbar']
      });  
    }
  }

  removeChips(ccEmail: string): void {
    const index = this.ccEmails.indexOf(ccEmail);
    if (index >= 0) {
      this.ccEmails.splice(index, 1);
    }
  }

  removeToChips(toEmail: string): void {
    const index = this.toEmails.indexOf(toEmail);
    if (index >= 0) {
      this.toEmails.splice(index, 1);
    }
  }
  
  isShow = false;
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  resendEmail(){
    let requestID = this.dialogTemp.AdditionalInfo.emailRequestId;
    let emailReqId = this.dialogTemp.AdditionalInfo.id;
    this.gstr2aService.reSendReconMail(requestID,emailReqId)
    .subscribe((data: any) => {
      if ('200'.localeCompare(data.status) === 0){
        this._snackBar.open('Mail resend succcessfully.', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['success-snackbar']
        });
      }
      else {
        this._snackBar.open('Mail sent failed.Please retry once again.', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
        });
      }
      this.dialogRef.close(true);
    },error => {
      this._snackBar.open('Error in mail service.' + error.message, 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['danger-snackbar']
      });
    });   
  }

  onFileSelected(event: any) {
    this.VendorCTINs = [];
    const fileTemp = event.target.files[0];
    this.file = fileTemp;
    this.fileName = fileTemp.name;
    var fileSize =event.target.files[0].size;
    if(Math.round(fileSize / 1024) > 2000)
    {
        this._snackBar.open('File size must be less than or equal to 2 MB', 'OK', {
          //  duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['alert-snackbar']
        });

        this.fileName = '';
        this.fileInput.nativeElement.value= "";
        return;
    }
  }

  hsnFileUpload(){
    if (this.fileName == null) {
      this._snackBar.open('Please Select File', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
      });
      return;
  }
    else if (
      (this.fileName.split('.').pop().trim() !== 'xlsx' && this.fileName.split('.').pop().trim() !== 'XLSX')) {
      this._snackBar.open('Please Select Correct File Format', 'OK', {
          //duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
      });
      return;
  } 
  else {
    const formData = new FormData();
      formData.append('HSNSummaryFile', this.file);
      var data = {formData:formData,override:this.OverwritedataModeSelected};
      this.dialogRef.close(data)
    // this.fileUploadedEvent.emit(formData);
  }
  this.file = null;
  this.fileName = null;
  }

  getTemplate(){
    const blob = new Blob();
    let fileData = "";
    if(this.dialogTemp.Type==23){
      fileData = "HSNSummaryTemplate.xlsx";
    }
    else if(this.dialogTemp.Type==28){
      fileData = "B2CSSummaryTemplate.xlsx";
    }
    else if(this.dialogTemp.Type==37){
      fileData = "VendorGSTINList.xlsx";
    }
    else if(this.dialogTemp.Type==41){
      fileData = "CustomerGSTINList.xlsx";
    }
    else{
      fileData = "DocSummaryTemplate.xlsx";
    }
    const url = '/assets/' + fileData;
    const anchor = document.createElement('a');
    anchor.download = fileData;
    anchor.href = url;
    anchor.click();
  }

  AcceptAction(){
    this.PeriodSelectedForAccept = this.reportPeriodSelected;
    if(this.ITCEligible == 1 && (this.PeriodSelectedForAccept != null && this.PeriodSelectedForAccept != undefined && this.PeriodSelectedForAccept != "")){
      this.PeriodSelectedForAccept = this.PeriodSelectedForAccept + " (U)";
    }
    let data = {period:this.PeriodSelectedForAccept,remarks:this.Autoremarks};
    this.dialogRef.close(data);
  }

  ClearAction(){
    this.reportPeriodSelected = null;
    this.PeriodSelectedForAccept = null;
    this.Autoremarks = null;
    this.dialogRef.close('clear');
  }

  getFinancialYear(){
    this.getColumnList.getFinancialYear().subscribe(
      (data: any) => {
        for (const item of data.data) {
           if (!this.financialYear.some(x => x.value === item)) {
            if(Number(item.substring(0,4))+1 <= Number(this.currenctFY.substring(0,4))+1){
              this.financialYear.push(item);
          }
           }
        }
        this.financialYear = this.financialYear.sort(function (a, b) {   
          return Number(b.substring(0,4)) - Number(a.substring(0,4))
      });
        this.fpDropDown();
      },
      error => {
        this._snackBar.open(
          'Error | Status: ' + error.status + ' | ' + error.error,
          'OK', 
          {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
          }
        );
      }
    );
  }

  fpDropDown() {
    if (this.financialYear.length > 0) {   
      for(var i =0; i < this.financialYear.length ; i++){
        let data=[];
        for(var j=0;j< this.listOfReportedMonth.length;j++){
          let val :ListMonthYear = {} as ListMonthYear;
          if(this.listOfReportedMonth[j].value == "01" || this.listOfReportedMonth[j].value == "02" || this.listOfReportedMonth[j].value == "03"){
            data[j] = this.listOfReportedMonth[j].value +"-"+ String(Number(this.financialYear[i].substring(0, 4))+1);
            val.month =  this.listOfReportedMonth[j].value +"-"+ String(Number(this.financialYear[i].substring(0, 4))+1);
            val.disabled = false;
          }
          else{
            data[j] = this.listOfReportedMonth[j].value +"-"+ this.financialYear[i].substring(0,4);
            val.month =this.listOfReportedMonth[j].value +"-"+ this.financialYear[i].substring(0,4);
            val.disabled = false;
          }         
        }
        let obj = { year: this.financialYear[i], month: data, disabled: false}
        this.PeriodValues.push(obj);
      }
      let currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      currentDate = currentDate.substr(5,2) + "-"+currentDate.substr(0,4);
      let currenFY = this.currenctFY.substr(0,4)+ "-"+this.currenctFY.substr(7,2);
      for(var i =0; i < this.PeriodValues.length ; i++){
if(this.PeriodValues[i].year == '2017-18'){
  this.PeriodValues[i].month = this.PeriodValues[i].month.filter(x=>x != '04-2017' && x != '05-2017' && x != '06-2017');
}
// if(currenFY == this.PeriodValues[i].year){

//   this.PeriodValues[i].month.filter(x=>x.substr(0,2) <= currentDate.substr(0,2) && x.substr(3,4) <= currentDate.substr(3,4));
// }
      }
     // this.PeriodValues.push({year:'Unclaimed',month:['Unclaimed'],disabled:false});
        }
    }

  expandToGet(group: any) {
    this.isExpandGet[group.year] = !this.isExpandGet[group.year];
  }
  selectedUnclamined(val){
    this.reportPeriodSelected = val;
  }
  onITCEligibleChange(ev){
   this.ITCEligible = ev.value;
  }
  ctinSelected(ctins){ 
 
    this.VendorCTINs = [];       
    if (ctins.length > 0) {
        ctins.forEach(element => {               
        this.VendorCTINs.push(element);    
        });           
    }       
}

  onOKClick(): void {

    if (this.file == null && this.VendorCTINs.length <= 0) {
      this._snackBar.open('Please Select File or Vendor GSTIN', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return;
    }

    if (this.file != null && this.VendorCTINs.length == 0) {
      if (this.fileName == null) {
        this._snackBar.open('Please Select File', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
        });
        return;
      } else if (
        (this.fileName.split('.').pop().trim() !== 'xlsx' && this.fileName.split('.').pop().trim() !== 'XLSX')
        && (this.fileName.split('.').pop().trim() !== 'xml' && this.fileName.split('.').pop().trim() !== 'XML')
        && (this.fileName.split('.').pop().trim() !== 'xls' && this.fileName.split('.').pop().trim() !== 'XLS')) {
        this._snackBar.open('Please Select Correct File Format', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['alert-snackbar']
        });
        return;
      }
      const formData = new FormData();
      formData.append('VendorGSTINFile', this.file);
      this.VendorGSTINRequestInputs = new VendorGSTINViewModel;
      this.VendorGSTINRequestInputs.FormData = formData;
      this.VendorGSTINRequestInputs.VendorCTINs = [];
      this.dialogRef.close(this.VendorGSTINRequestInputs)
    }
 
    if (this.file == null && this.VendorCTINs.length > 0) {
      this.VendorGSTINRequestInputs = new VendorGSTINViewModel;
      this.VendorGSTINRequestInputs.FormData = null;
      this.VendorGSTINRequestInputs.VendorCTINs = this.VendorCTINs;
      this.dialogRef.close(this.VendorGSTINRequestInputs)
    }

  }

  refreshVendorGSTIN(){
    if (this.EntityGSTINList != '' && this.EntityGSTINList != undefined && this.EntityGSTINList != null &&
        this.PeriodList != '' && this.PeriodList != undefined && this.PeriodList != null) {
      this.gstr2aService.getVendorCtins(this.EntityGSTINList, this.PeriodList).subscribe(data => {
        console.log(data);
        if ('200'.localeCompare(data.status) === 0) {
          data.data.forEach(element => {
            const Singlegstin: ListOfGstin = {} as ListOfGstin;
            Singlegstin.GSTINValue = element;
            Singlegstin.value = element;
            this.listOfCtin.push(Singlegstin);
          });
        }
      });
}
    }
    EmitClaimDetails(): void {
        this.dialogRef.close(this.claimEligiblePull);
    }
    SendReconTypeDetails() {
        this.dialogRef.close(this.selectedReconTypeForRefund);
    }
    SendReconTypeDetails_Inwards() {

        this.dialogRef.close(this.selectedReconTypeForRefund_Inwards);
    }
    closeRefundPopup() {
        this.dialogRef.close();
    }
    onOverwritedataModeChange(mrChange: MatRadioChange) {
      if (mrChange.value == 0) {
          this.OverwritedataModeSelected = "Yes";
      }
      else {
          this.OverwritedataModeSelected = "No";
      }
      console.log(this.OverwritedataModeSelected);

  }

  historyPageEvent(event: any) {
    this.currentPage = event.currentPage;
    this.pageSize = event.pageSize;
    this.getForceMatchGridDetails(this.currentPage, this.pageSize);
  }

  getForceMatchGridDetails(currentPage,pageSize) {
    this.filterPagination = true;
    this.gstr2aService.getForceMatchGridDetails(this.reconType,currentPage,pageSize).subscribe(
      data => {
        if ('200'.localeCompare(data.value.status) === 0) {
          this.forceMatchGrid.next(data.value.data.dataList);
          this.forceMatchGridPageCount.next(data.value.data.count);
        }
        else {
          this._snackBar.open(data.value.message, 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['alert-snackbar']
          });
        }
      })
  }

  getForceColumnList(){
    let forceMatchColumnList = [{
      columnName: 'requestID',
      alias: 'Request ID',
      isDefault: true
    },
    {
      columnName: 'fileName',
      alias: 'File Name',
      isDefault: true
    },
    {
      columnName: 'status',
      alias: 'Status',
      isDefault: true
    },
    {
      columnName: 'fileId_Download',
      alias: 'Uploaded File',
      isDefault: true
    },
    {
      columnName: 'errorFileID_Download',
      alias: 'Error File',
      isDefault: true
    },
    {
      columnName: 'requestedOn',
      alias: 'Requested On',
      isDefault: true
    }
  ];
  this.forceMatchColumnList.next(forceMatchColumnList);
  }

  selectstatusType(event){
  }
}
