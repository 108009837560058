// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    authURL: 'https://inbolton.deloitte.com/',
     clientId: 'boltonawsprodb-asp-ui',
     fileURL: 'https://inboltonapi-b.deloitte.com/file/',
     production: true,
     requireHttps: true,
     resourceURL: 'https://inboltonapi-b.deloitte.com',
     showDebugInfo: false,
     userManagementServiceUrl: '',
     identityManagementServiceUrl: 'https://inbolton.deloitte.com/',
     einvoiceEwayBillServiceUrl: 'https://inboltonapi-b.deloitte.com',
     gstrServiceUrl: 'https://inboltongstrapi-b.deloitte.com',
     infrastructureServiceUrl: '',
     returnServiceUrl:'https://inboltonreturnsapi-b.deloitte.com',
     reportServiceURL :'https://inboltonreportapi-b.deloitte.com'
   };
   
   /*
    * For easier debugging in development mode, you can import the following file
    * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
    *
    * This import should be commented out in production mode because it will have a negative impact
    * on performance if an error is thrown.
    */
   import 'zone.js/dist/zone-error';  // Included with Angular CLI.
   