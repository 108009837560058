export interface ReportRequestViewModel {
    GSTIN : string;
    Period : string;
    StartDate : string;
    EndDate : string;
    ReportType : string;
    OptionalFields: string[];
    AdditionalParameters: string[];
}

export interface MultiReportRequestViewModel {
    GSTIN : string[];
    Period : string[];
    StartDate : string;
    EndDate : string;
    ReportType : string;
    OptionalFields: string[];
    AdditionalParameters: string[];
}

export interface ReportRequestMultiViewModel {
    GSTIN: string[];
    Period: string[];
    ReportType: string;
    ActionType: string;
}
export class GetAutoReconUIGrid{
    ReportedPeriod : string;
    EntityGSTIN : string;
    RequestedBy : string;
    ReturnType :string;
}
