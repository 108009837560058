import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileTemplates } from '../../../shared/models/get/get-md-beow-upload/get-file-history';
import { GetUploadFileService } from '../../../shared/services/get/get-srv-boew-upload/get-upload-file.service';
import { GlobalSrvConstantsService } from '../../../shared/services/global/global-constants/global-srv-constants.service';
import { PostFileUploadService } from '../../../shared/services/post/pst-srv-boew-upload/post-file-upload.service';
import { UploadDocumentService } from '../../../shared/services/uploadDoument/upload-document.service';
import { downloadFile } from '../../../shared/helpers/file-helper';
import { getCurrentDateTimeForFileName } from '../../../shared/helpers/date-time-helper';
import { templateName } from '../../../shared/Constants';
import { DatePipe } from '@angular/common';
import { MatRadioChange } from '@angular/material';
import { GlobalApiService } from 'src/app/shared/services/global/global-api/global-api.service';

@Component({
    selector: 'app-sub-file-upload-form',
    templateUrl: './sub-file-upload-form.component.html',
    styleUrls: ['./sub-file-upload-form.component.css'],
    providers: [DatePipe]
})

export class SubFileUploadFormComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    selectedFileName: any;
    fileDesc: string;
    fileName: string;
    file: any;
    isUploading = false;
    templateList: FileTemplates[] = [];
    templateSelected: string;
    templateSelectedName: string;
    fileSizeLimit: number = 2000;
    fileSizeLimitMsg: string;
    fileSize: number;
    documentNumber: string = null;
    dateSelected = null;
    IsReturnGSTRservice: boolean = false;    
    IsITC04File: boolean = false;
    IsGSTR8File: boolean = false;
    IsGSTR7File: boolean = false;
    IsGSTR1DOCSFile:boolean = false
    OverwritedatabuttonVisible: boolean = false;
    GenerateEinv: boolean = false;
    GenerateEwb: boolean = false;
    GenerateReturns: boolean = false;
    TransactionType: string;
    IsLargeFile: boolean = false;
    IsIRNBookFile: boolean = false;
    uploadFile = new FormGroup({
        fileDescription: new FormControl(new FormControl(this.fileDesc)),
        templateID: new FormControl(new FormControl(this.templateSelected)),
        invoice: new FormControl(['']),
    });
    isDownloadTemplateButtonEnable = true;

    @Output() fileUploadedEvent = new EventEmitter<string>();
    @Output() documentSearchEvents = new EventEmitter<any>();
    OverwritedataModeSelected: string = "No";
    // tslint:disable-next-line: variable-name
    constructor(
        private _snackBar: MatSnackBar,
        private _postUploadFile: PostFileUploadService,
        private _getUploadFile: GetUploadFileService,
        private uploadDocumentService: UploadDocumentService,
        public datepipe: DatePipe) {

    }

    ngOnInit() {
        this.getUploadTemplates();
    }

    onFileSelected(event: any) {
        const fileTemp = event.target.files[0];
        this.file = fileTemp;
        this.fileName = fileTemp.name;
        var fileSize = event.target.files[0].size;
        this.fileSize = event.target.files[0].size;

        if (this.templateSelectedName != undefined) {

            this.validateFileSize();
        }

        this.uploadFile.get('invoice').setValue(this.file);
    }

    onSubmit() {
        if (this.fileName == null) {
            this._snackBar.open('Please Select File', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        }
        else if (
            (this.fileName.split('.').pop().trim() !== 'xlsx' && this.fileName.split('.').pop().trim() !== 'XLSX')
            && (this.fileName.split('.').pop().trim() !== 'xls' && this.fileName.split('.').pop().trim() !== 'XLS')
            && (this.fileName.split('.').pop().trim() !== 'xlsb' && this.fileName.split('.').pop().trim() !== 'XLSB')
            && (this.fileName.split('.').pop().trim() !== 'xml' && this.fileName.split('.').pop().trim() !== 'XML')
            && (this.fileName.split('.').pop().trim() !== 'csv' && this.fileName.split('.').pop().trim() !== 'CSV')
            && (this.fileName.split('.').pop().trim() !== 'pdf' && this.fileName.split('.').pop().trim() !== 'PDF')
        ) {
            this._snackBar.open('Please Select Correct File Format', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        } else if (this.fileDesc == null || this.fileDesc === '' || this.fileDesc === undefined) {
            this._snackBar.open('Description is Required', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        } else if (this.templateSelected == null || this.templateSelected === undefined) {
            this._snackBar.open('Please select File Template', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        }
        else {
            const formData = new FormData();
            formData.append('invFile', this.uploadFile.get('invoice').value);
            formData.append('invDesc', this.uploadFile.get('fileDescription').value);
            formData.append('invtemplateID', this.templateSelected);
            formData.append('OverwriteData', this.OverwritedataModeSelected);
            this.isUploading = true;
            if ((this.IsLargeFile == true ) && ((this.fileName.split('.').pop().trim() == 'csv'))) 
            {
                this._postUploadFile.postFileDatatoReturnService(formData)
                    .subscribe
                    (
                        data => {
                            if ('200'.localeCompare(data.status) === 0) {
                                this._snackBar.open('File Uploaded Successfully', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                                this.fileUploadedEvent.emit('1');
                                this.isUploading = false;
                                this.templateSelected = undefined;
                            }
                        },
                        error => {
                            let errorStatus;
                            let errorMessage;
                            if (error.error.message !== undefined) {
                                errorStatus = error.error.status;
                                errorMessage = error.error.message.substring(0, 90);
                            } else {
                                errorStatus = error.status;
                                errorMessage = error.error;
                            }
                            this._snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['danger-snackbar']
                            });
                            this.isUploading = false;
                        }
                    );

            }
            else if ((this.IsReturnGSTRservice == true || this.IsITC04File == true || this.IsGSTR8File == true || this.IsGSTR7File == true || this.IsGSTR1DOCSFile == true || this.IsIRNBookFile == true) && ((this.fileName.split('.').pop().trim() == 'xlsx' || this.fileName.split('.').pop().trim() == 'XLSX')
                || (this.fileName.split('.').pop().trim() == 'xls' || this.fileName.split('.').pop().trim() == 'XLS'|| this.fileName.split('.').pop().trim() == 'csv'|| this.fileName.split('.').pop().trim() == 'xlsb'))) {
                this._postUploadFile.postFileDatatoGSTR(formData)
                    .subscribe
                    (
                        data => {
                            if ('200'.localeCompare(data.status) === 0) {
                                this._snackBar.open('File Uploaded Successfully', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                                this.fileUploadedEvent.emit('1');
                                this.isUploading = false;
                                this.templateSelected = undefined;
                                this.OverwritedatabuttonVisible = false;
                                this.OverwritedataModeSelected = "No";

                            }
                        },
                        error => {
                            let errorStatus;
                            let errorMessage;
                            if (error.error.message !== undefined) {
                                errorStatus = error.error.status;
                                errorMessage = error.error.message.substring(0, 90);
                            } else {
                                errorStatus = error.status;
                                errorMessage = error.error;
                            }
                            this._snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['danger-snackbar']
                            });
                            this.isUploading = false;
                        }
                    );

            } 
            else if((this.fileName.split('.').pop().trim() == 'pdf' || this.fileName.split('.').pop().trim() == 'PDF') && 
            (this.templateSelectedName === 'GSTR1-SUMMARY' || this.templateSelectedName === 'GSTR3B-SUMMARY')){
                this._postUploadFile.postSummaryDatatoGSTR(formData)
                    .subscribe
                    (
                        data => {
                            if ('200'.localeCompare(data.status) === 0) {
                                this._snackBar.open('File Uploaded Successfully', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                                this.fileUploadedEvent.emit('1');
                                this.isUploading = false;
                                this.templateSelected = undefined;
                                this.OverwritedatabuttonVisible = false;
                                this.OverwritedataModeSelected = "No";

                            }
                        },
                        error => {
                            let errorStatus;
                            let errorMessage;
                            if (error.error.message !== undefined) {
                                errorStatus = error.error.status;
                                errorMessage = error.error.message.substring(0, 90);
                            } else {
                                errorStatus = error.status;
                                errorMessage = error.error;
                            }
                            this._snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['danger-snackbar']
                            });
                            this.isUploading = false;
                        }
                    );

            }
            else {
                this._postUploadFile.postFileData(formData)
                    .subscribe
                    (
                        data => {
                            if ('200'.localeCompare(data.status) === 0) {
                                this._snackBar.open('File Uploaded Successfully', 'OK', {
                                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                    panelClass: ['success-snackbar']
                                });
                                this.fileUploadedEvent.emit('1');
                                this.isUploading = false;
                                this.templateSelected = undefined;
                                this.OverwritedatabuttonVisible = false;
                                this.OverwritedataModeSelected = "No";

                            }
                        },
                        error => {
                            let errorStatus;
                            let errorMessage;
                            if (error.error.message !== undefined) {
                                errorStatus = error.error.status;
                                errorMessage = error.error.message.substring(0, 90);
                            } else {
                                errorStatus = error.status;
                                errorMessage = error.error;
                            }
                            this._snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['danger-snackbar']
                            });
                            this.isUploading = false;
                        }
                    );

            }
            
            this.uploadFile.markAsPristine();
            this.uploadFile.markAsUntouched();
            this.uploadFile.updateValueAndValidity();
            this.uploadFile.reset();
            this.fileName = null;
            this.templateSelected = null;
            this.templateSelectedName = null;
            this.fileInput.nativeElement.value = '';
            this.templateSelectedName = undefined;
        }
    }

    getUploadTemplates() {
        this._getUploadFile.getUploadTemplates().subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    if (data.data == null || data.data === undefined) {
                        return;
                    } else if (data.data.count === 0) {
                        return;
                    } else {
                        data.data.forEach(element => {
                            const fileTemplates: FileTemplates = {} as FileTemplates;
                            fileTemplates.value = element.templateId;
                            fileTemplates.template = element.templateName;
                            fileTemplates.IsOverwritedatabuttonVisible = element.allowOverwrite;
                            fileTemplates.GenerateEinv = element.generateEinv;
                            fileTemplates.GenerateEwb = element.generateEwb;
                            fileTemplates.GenerateReturns = element.generateReturns;
                            fileTemplates.TransactionType = element.transactionType;  
                            this.templateList.push(fileTemplates);                        
                        });
                    }
                }
            },
            error => {
                this._snackBar.open(
                    'Error | Status: ' + error.status + ' | ' + error.error,
                    'OK',
                    {
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
                        panelClass: ['danger-snackbar']
                    }
                );
            }
        );
    }

    downloadTemplate() {
        this.isDownloadTemplateButtonEnable = false;

        this.uploadDocumentService.getUploadDocumentTemplates().subscribe((data: any) => {
            const fileName = templateName.DocumentUpload + '_' + getCurrentDateTimeForFileName() + '.zip';
            downloadFile(data, fileName);
        });

        this.isDownloadTemplateButtonEnable = true;
    }

    templateChange(templateValue: any) {
        if (templateValue == null) {
            this.templateSelected = null;
            this.templateSelectedName = null;
        }
        else {

            this.templateSelected = templateValue.value;
            this.templateSelectedName = templateValue.template;
            this.OverwritedatabuttonVisible = templateValue.IsOverwritedatabuttonVisible;
            this.GenerateEwb=templateValue.GenerateEwb;
            this.GenerateEinv=templateValue.GenerateEinv;
            this.GenerateReturns=templateValue.GenerateReturns;
            this.TransactionType=templateValue.TransactionType;
            this.validateFileSize();
        }
    }

    onReset() {
        this.documentNumber = '';
        this.dateSelected = '';
        let data = { documentNumber: this.documentNumber.trim(), dateSelected: this.dateSelected };
        this.documentSearchEvents.emit(data);
    }
    searchDocuments() {
        if ((this.documentNumber == null || this.documentNumber.trim() == "") && (this.dateSelected == null || this.dateSelected == "" || this.dateSelected == undefined)) {
            this._snackBar.open('Please Enter The Document Number or Uploaded Date', 'OK', {
                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                panelClass: ['alert-snackbar']
            });
            return;
        }
        if (this.dateSelected == undefined) {
            this.dateSelected = '';
        }
        if (this.documentNumber != null) {
            this.documentNumber = this.documentNumber.trim();
        }
        let latest_date = '';
        if (this.dateSelected != undefined && this.dateSelected != null && this.dateSelected != '') {
            latest_date = this.datepipe.transform(this.dateSelected, 'yyyy-MM-dd');
        }
        let data = { documentNumber: this.documentNumber, dateSelected: latest_date };
        this.documentSearchEvents.emit(data);
    }

    onOverwritedataModeChange(mrChange: MatRadioChange) {
        if (mrChange.value == 0) {
            this.OverwritedataModeSelected = "Yes";
        }
        else {
            this.OverwritedataModeSelected = "No";
        }
        console.log(this.OverwritedataModeSelected);

    }


    validateFileSize() {
        
        if ((this.GenerateEinv == false && this.GenerateReturns==true &&this.GenerateEwb==false&&this.TransactionType=="Outward")||this.TransactionType=="Inward" ||this.TransactionType=="GSTR8"||this.TransactionType=="IRNBook") {

            this.fileSizeLimit = 51200;

            this.fileSizeLimitMsg = 'File size must be less than or equal to 50 MB';

        }
        else {

            this.fileSizeLimit = 2000;

            this.fileSizeLimitMsg = 'File size must be less than or equal to 2 MB';

        }
        
        if(this.templateSelectedName == "Standard ITC04 Template" || this.templateSelectedName == "UpdateShippingBill"){
             this.IsITC04File = true;
        }

        if(this.templateSelectedName == "Standard GSTR8 Template"){
            this.IsGSTR8File = true;
        }
        if(this.templateSelectedName == "Standard GSTR7 Template"){
            this.IsGSTR7File = true;
        }
        if (this.templateSelectedName == "Standard GSTR1 DOCS Template" || this.templateSelectedName == "HSN Summary" || this.templateSelectedName == "B2CS Summary") {
            this.IsGSTR1DOCSFile = true;
        }
        if(this.templateSelectedName == "Input Data of IRN from Books"){
            this.IsIRNBookFile = true;
        }
        if(this.templateSelectedName == "FK Seller Outward Template" ||
        (this.GenerateEinv == false && this.GenerateReturns==true &&this.GenerateEwb==false&&this.TransactionType=="Inward" && Number(GlobalApiService.getCurrentOrganizationID())==77))
            this.IsLargeFile = true;

        if (this.fileSize != null) {
            // if ((this.GenerateEinv == false && this.GenerateReturns==true &&this.GenerateEwb==false&&this.TransactionType=="Outward")||(this.TransactionType=="Inward") && Math.round(this.fileSize / 1024) <= this.fileSizeLimit)
            // {
            //     this.IsReturnGSTRservice = true;
            // }
            if (((this.GenerateEinv == false && this.GenerateReturns==true &&this.GenerateEwb==false&&this.TransactionType=="Outward")||(this.TransactionType=="Inward") || (this.TransactionType.includes("Moowr"))) && Math.round(this.fileSize / 1024) <= this.fileSizeLimit)
            //&& !(this.templateSelectedName == "MasterCard Inward Standard Template" && Number(GlobalApiService.getCurrentOrganizationID()) == 316))
            {
                this.IsReturnGSTRservice = true;
            }
            else
            {
                this.IsReturnGSTRservice = false;
                if (Math.round(this.fileSize / 1024) > this.fileSizeLimit) {

                    this._snackBar.open(this.fileSizeLimitMsg, 'OK', {
    
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
    
                        panelClass: ['alert-snackbar']
    
                    });
    
    
                    this.fileName = '';
    
                    this.fileInput.nativeElement.value = "";
    
                    this.fileSize = null;
    
                    return;
    
                }
            }            
           
        }
    }
}

