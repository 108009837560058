import { AddGstinRequestViewModel } from '../../../../shared/models/usermanagment/UserManagement';
import { isStringNullOrEmpty } from  '../../../../shared/helpers/common-helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { GetOrganizationDetail, OrganizationDetail } from '../../../../shared/models/get/get-md-global/get-organization-detail';
import { environment } from 'src/environments/environment';
import { AccessTokenReq, RevokeAccessTokenReq } from '../../../../shared/models/global/acc-token/access-token-req';
import { ApplicationRoles, AddUserRequestViewModel, UsersData, UserInfo, EInvoiceRequest, MasterClientSourceSytems } from '../../../models/usermanagment/UserManagement';
import { EinvoiceEWayBillByIRNRequestViewModel } from 'src/app/shared/models/get/get-md-boew-inv/get-invoice-list';
import { TemplateMapMainModel, TemplateMappingModel } from 'src/app/shared/models/get/get-md-beow-upload/get-file-history';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

@Injectable({
    providedIn: 'root'
})
export class GlobalApiService {
    constructor(private _httpClient: HttpClient) { }
    public static GLOBALAPIURL = environment.resourceURL;
    public static GLOBALFILEURL = environment.fileURL;   
    public static GLOBALREPORTURL = environment.reportServiceURL;   
    public static GLOBALGSTRFILEURL = environment.gstrServiceUrl + '/ReturnsReport/';
    public static GLOBALGSTRHSNCLSURL = environment.gstrServiceUrl;

    public static ORGANIZATIONDATA: OrganizationDetail[];
    public static Token: any;
    public userroles: string[] = [];
    public IsViveEnabled:boolean = false;
    public IsHsnClsEnabled:boolean = false;
    public SourceSystemDetails:MasterClientSourceSytems[]= [];
    public currentOrganizationUsers: UsersData[] = [];
    public applicationroles: ApplicationRoles[] = [];
    public searchGridRefresh:BehaviorSubject<boolean> = new BehaviorSubject(null);
    public isRefresh:boolean = false;
    InactiveSession = new BehaviorSubject<boolean>(false);
    DocSearchRequestRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    RefreshUserListGrid : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    clearDocumentSearchDate : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    DisableDownloadButton : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    stopGetReturnStatusLoader : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
   
    HasEinvoiceAutoGenerate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    HasEwayAutoGenerate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    HasDownloadInvoices : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ModifyUserQueryParameters : BehaviorSubject<any> = new BehaviorSubject<any>(isStringNullOrEmpty);
    isReturnEnabled : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    isGSTNUpdated : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ShowReportHistoryGrid:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    ManageOrganizationID : BehaviorSubject<any> = new BehaviorSubject<any>(null);
    RefreshSelectTemplate : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ManageOrganizationNameAndID : BehaviorSubject<any> = new BehaviorSubject<any>(null);
    selectedReportName : BehaviorSubject<string> = new BehaviorSubject<string>("");

    public static getCurrentOrganizationID(): string {
        let value: OrganizationDetail;
        value = JSON.parse(sessionStorage.getItem('Organization')) as OrganizationDetail;
        if(value == undefined)
        {
            return "";
        }
        return String(value.organizationId);
    }

    public static getCurrentOrganizationName(): string {
        let value: OrganizationDetail;
        value = JSON.parse(sessionStorage.getItem('Organization')) as OrganizationDetail;
        if(value)
        return String(value.organizationName);
        else
        return "";
    }

    async getOrganizationDetail() {
        // if (sessionStorage.getItem('Organization') != null && sessionStorage.getItem('Organization').length > 0) {
        //     return;
        // }
        const response: any = await this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/org'
        ).toPromise();
        const activeOrg:any = response.data.filter(item=> item.isActive == true);
        sessionStorage.setItem(
            'Organization', JSON.stringify(activeOrg[0])
        );

    }

   public switchOrganization(orgIdToSwitch: number) { 
    let revokeAccessTokenReq: RevokeAccessTokenReq = new RevokeAccessTokenReq();
    revokeAccessTokenReq.Token = sessionStorage.getItem('id_token');
      return this._httpClient.post(
                GlobalApiService.GLOBALAPIURL+ '/org/switchOrganization?orgIdToSwitch='+orgIdToSwitch,revokeAccessTokenReq)
        
                
    } 

    public addUser(userDetails: AddUserRequestViewModel, organizationID: string, isSuperAdmin: boolean) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/AddUser?&OrganizationID=' + organizationID + '&IsSuperAdmin=' + isSuperAdmin, userDetails,
        )
    }
    public addGstin(gstinDetails: AddGstinRequestViewModel, organizationID: string, isSuperAdmin: boolean) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/AddGstin?&OrganizationID=' + organizationID + '&IsSuperAdmin=' + isSuperAdmin, gstinDetails,
        )
      }

    public getEntityGSTINDetails(GSTIN : string, organizationID: string, isSuperAdmin: boolean )
    {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/EntityDetails' + '?GSTIN=' + GSTIN + '&OrganizationID=' + organizationID + '&IsSuperAdmin=' + isSuperAdmin,
        )
    }

    public getUserRoles(orgId: number) {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/Roles' + '?organizationId=' + orgId
        )
    }
    public getApplicationRoles(organizationDetails, isSuperAdmin) {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/ApplicationRoles?organizationDetails=' + organizationDetails + '&isSuperAdmin=' + isSuperAdmin,
        )
    }
    public getGstnsByOrganization(organizationDetails,isSuperAdmin) {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/Gstns?organizationDetails='+ organizationDetails + '&isSuperAdmin=' + isSuperAdmin,
        )
    }
    public getUsersByOrganization(organizationDetails,isSuperAdmin) {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/UsersData?organizationDetails='+ organizationDetails + '&isSuperAdmin=' + isSuperAdmin,
        )
    }

    public getUserDetails(userinfo: UserInfo,selectedOrganizationDetails,isSuperAdmin) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/User?&OrganizationID=' + selectedOrganizationDetails+ '&IsSuperAdmin=' + isSuperAdmin, userinfo
    );
    }

    public GenerateEwbByIrn(ewbByIrn: EinvoiceEWayBillByIRNRequestViewModel) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/api/ei/generateEwayBillbyIrnUI', ewbByIrn
        )
    }

    public getInvoices(einvoice: EInvoiceRequest) {
        return this._httpClient.post(
            GlobalApiService.GLOBALREPORTURL + '/file/einvoicedownload', einvoice
        )
    }

    public generateEinvoiceJson(outwardDocumentHeaderID : number[]) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/ei/einvoicejsondownload', outwardDocumentHeaderID
        )
    }


    async getOrganizationDetailWithAuthorization(authorization: string) {
        if (sessionStorage.getItem('Organization') != null && sessionStorage.getItem('Organization').length > 0) {
            return;
        }
        const response: any = await this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/org', { headers: { Authorization: authorization } }
        ).toPromise();
        sessionStorage.setItem(
            'Organization', JSON.stringify(response.data[0])
        );
    }

    async changeOrganizationDetail() {
        GlobalApiService.ORGANIZATIONDATA = {} as OrganizationDetail[];
        const response: any = await this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/org', 
        ).toPromise();
        GlobalApiService.ORGANIZATIONDATA = response.data;
    }

    downloadFile(fileType, fileID): Observable<any> {
        return this._httpClient.get(
            GlobalApiService.GLOBALFILEURL + fileType + '/' + fileID, {
             responseType: 'blob'
        });
    }

    downloadTaxNotice(taxNoticeID): Observable<any> {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/FileDownload/GetTaxNoticeFile' + '/' + taxNoticeID, {         
            responseType: 'blob'
        });
    }

    downloadGSTRFile(fileType, fileID): Observable<any> {
        return this._httpClient.get(
            GlobalApiService.GLOBALGSTRFILEURL + fileType + '/' + fileID, {
             responseType: 'blob'
        });
    }
    getSuccessFile(fileType, fileID): Observable<any> {
        return this._httpClient.get(
            GlobalApiService.GLOBALFILEURL + fileType + '/' + fileID + '/' + 'successFile', {
            // responseType: 'blob'
        });
    }
    generateSuccessFile(uploadId): Observable<any> {
        return this._httpClient.post(
            GlobalApiService.GLOBALREPORTURL + '/file/' + uploadId  , {
          
        });
    }

    public getAccessToken(accessRequest: AccessTokenReq) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/accesstoken', accessRequest
        )
    }
    public getTokenDetails(orgId) {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/tokenDetails'
        )
    }
    public GetSourceSystemDetails() {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/org/sourcesystemdetails'
        )
    }
    public deleteTokenDetails(tokenId) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/deleteToken', tokenId
        )
    }
    public revokeAccessToken(revokeAccessTokenReq:RevokeAccessTokenReq) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/revokeAccessToken', revokeAccessTokenReq
        )
    }
    public getCurrentAccessToken():string {
        return sessionStorage.getItem('access_token');
    }

    public getGstinDetailsByOrganization(organizationDetails,isSuperAdmin) {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/GstnsData?organizationDetails='+ organizationDetails + '&isSuperAdmin=' + isSuperAdmin,
        )
    }
    public getAdditionalColumnsForStandardReport(): Observable<any> {
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/Reports/getMappingColumns');   
    }

    public getPendingQueuedIRNs(organizationId,startDate,endDate,curPage,pgSize): Observable<any> {
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/api/pending/irnstatus?organizationId='+ organizationId + '&startDate='+ startDate + '&endDate='+ endDate + '&currentPage='+curPage+ '&pageSize='+pgSize);   
    }

    public postPendingQueuedIRNs(organizationId,stuckList): Observable<any> {
        return this._httpClient.post(GlobalApiService.GLOBALAPIURL + '/api/pending/QueueRequest', stuckList);   
    }

    public postPendingQueuedIRNwithGet(organizationId,stuckList): Observable<any> {
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/api/pending/QueueRequestByIRNId?organizationId='+ organizationId + '&stuckIds=' + stuckList);   
    }

    public getOrganisationDetails():Observable<any>{
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/org');
    }

    public downloadPendingReProcessLogs():Observable<any>{
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/api/pending/download');
    }

    public getPowerBIReporturl(reportType): Observable<any> {
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/Reports/getAnalyticsReportURL?type='+reportType);   
    }
    public getSelectedTemplateDetails(templateID,organizationDetails): Observable<any> {
        return this._httpClient.get(GlobalApiService.GLOBALAPIURL + '/UserDetails/stdtemplateDetails?templateID='+ templateID + '&organizationDetails='+ organizationDetails);   
    }

      public doTemplateMapping(templateDataDomainModel:TemplateMappingModel) {
        return this._httpClient.post(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/templatemapping', templateDataDomainModel
        )
    }

    public validateUser():Observable<any>{
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/ValidateUser'
        );
    }
    downloadGSTRJsonFile(fileID): Observable<any> {
        
   
        return this._httpClient.get(
            environment.gstrServiceUrl + '/ReturnsReport/DownloadJson?file=' + fileID
        );
    }

    getLogout(): Observable<any> {
        return this._httpClient.get(
            GlobalApiService.GLOBALAPIURL + '/UserDetails/LogOutWithBrowserClose'
        );
    }
   
    downloadForceHistoryFile(fileID,fileType): Observable<any> {
        return this._httpClient.get(
            GlobalApiService.GLOBALGSTRHSNCLSURL + '/GSTR2A/autorecon/downloadfromfmhistory?fileId=' + fileID + '&fileType=' + fileType , {
             responseType: 'blob'
        });
    }
}
